import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const ContactPage = () => {
    return (
        <Layout> 
            <Head title="Contact"/>
            <h1>Contact</h1>
            <p>I you wish to get in contact with me, send me an email at <a href="mailto:liljegren.gustaf@gmail.com">liljegren.gustaf@gmail.com</a></p>
            <br/>
            <p>For support or inquiries regarding the klicat app, send an email to <a href="mailto:klicat.app@gmail.com">klicat.app@gmail.com</a> or view the <a href="https://www.websitepolicies.com/policies/view/niO6Iyiy">Privacy policy for the klicat app</a></p>
        </Layout>
    )
}

export default ContactPage
